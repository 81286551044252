<template>
    <Transition name="scroll-y-reverse-transition" mode="out-in">
        <v-container class="" id="home-container">
            <auth></auth>
            <!--View change-->
            <Transition name="scroll-y-reverse-transition" mode="out-in">
                <appdash v-if="nav_val == 'apps'"
                         :app_list="app_list"
                         :special_app_list="special_app_list"
                         :apps_finished_loading="apps_finished_loading"
                         :tURL="tURL"
                         :fakeLoadingCards="fakeLoadingCards"
                         @clear_loadingapps="clear_loadingapps" @submitFavApp="submitFavApp"></appdash>
                <admindash v-if="nav_val == 'users'"></admindash>
                <rolesdash v-if="nav_val == 'roles'"></rolesdash>
            </Transition>


            <v-bottom-navigation v-model="nav_val" fixed>
                <v-btn value="apps" id="apps_button">
                    <span>Apps</span>
                    <v-icon>$appViewDash</v-icon>
                </v-btn>

                <v-btn value="users" v-show="admin_mode" id="admin_button">
                    <span>Users</span>
                    <v-icon>$accountEdit</v-icon>
                </v-btn>

                <v-btn value="roles" v-show="admin_mode" id="admin_button2">
                    <span>Roles</span>
                    <v-icon>$databaseCog</v-icon>
                </v-btn>

                <v-btn value="logout" @click="logout" id="logout_button">
                    <span>Logout</span>
                    <v-icon>$logout</v-icon>
                </v-btn>
            </v-bottom-navigation>

            <v-overlay absolute :value="overlay_loading">
                <v-progress-circular indeterminate :size="80" :width="7"
                                     color="primary"></v-progress-circular>
            </v-overlay>

        </v-container>
    </Transition>
</template>


<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import appdash from "./appdash";
    import auth from "./auth";
    const admindash = () => import('./admindash')
    const rolesdash = () => import('./rolesdash')
    export default {
        name: 'Home',
        mixins: [bus_common],
        props: ["navigation"],
        components: { appdash, admindash, auth, rolesdash },
        data() {
            return {
                admin_mode: false,
                nav_val: this.navigation,
                overlay_loading: false,
                apps_finished_loading: false,
                special_app_list: [],
                special_apps: ['well'],
                app_list: [],
                tURL: "",
                /*loginURL: 'https://localhost:44365/'*/
                loginURL: this.$store.state.appURL,
                fakeLoadingCards: 8

            };
        },
        methods: {
            logout() {
                let callback = () => {

                    window.location.replace(this.loginURL);

                }
                this.swall_diag("Log Out?", "Are you sure you would like to log out?", "question", callback, "Yes", "No", null)
            },
            async init() {

                var form = { token: this.$store.state.user.token };
                await this.$axios.post('api/home/apps', form)
                    .then(response => {
                        this.admin_mode = response.data.bAdmin;

                        // For well being we need to create a seperate app list
                        // To do so, we need to filter and remove apps based on the app abbrv (better than name?)
                        const propName = 'app_abbrv';
                        const propValues = this.special_apps;
                        let parsing = this.removeItemsByPropertyValue(response.data.app_list, propName, propValues)
                        this.app_list = parsing.remainingItems;
                        this.special_app_list = parsing.removedItems;

                        this.tURL = response.data.token;
                        this.apps_finished_loading = true;
                        setTimeout(() => {
                            this.fakeLoadingCards = this.app_list.length;
                        }, 1000)
                    }).catch(error => {
                        if (error.response) {
                            this.swall_it("Error!", error.response.data.msg, "error", null, null);
                        }
                    });
                this.overlay_loading = false;
            },

            // For well being we need to create a seperate app list
            removeItemsByPropertyValue(arr, propName, propValues) {
                if (!Array.isArray(propValues)) {
                    console.error('propValues must be an array');
                }

                const removedItems = [];
                const remainingItems = arr.filter(item => {
                    if (propValues.includes(item[propName])) {
                        removedItems.push(item);
                        return false;
                    }
                    return true;
                });

                // If we fail to remove any, just return the original arr
                if (removedItems.length === 0) {
                    //console.error('No items found with the specified property value');
                    return { removedItems: [], remainingItems: arr };
                }

                return { removedItems, remainingItems };
            },

            clear_loadingapps() {
                for (var i = 0; i < this.app_list.length; i++) {
                    if (this.app_list[i].isLoading == true) {
                        this.app_list[i].isLoading = false;
                    }
                }
            },

            async submitFavApp(payload) {

                // To trigger loading of apps, keep a copy of current app lisiting and clear current
                let app_list_copy = JSON.parse(JSON.stringify(this.app_list));
                this.app_list = [];

                let callback = () => { window.location.replace(this.loginURL); }

                var form = { token: this.$store.state.user.token, appname: payload.app.appname, favoriting: payload.isfavoriting };
                await this.$axios.post('api/home/favorite_app', form)
                    .then(response => {
                        const propName = 'app_abbrv';
                        const propValues = this.special_apps;
                        let parsing = this.removeItemsByPropertyValue(response.data.app_list, propName, propValues)
                        this.app_list = parsing.remainingItems;

                    }).catch(error => {
                        if (error.response) {

                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                            // Set data back on an error
                            this.app_list = app_list_copy;

                        }
                    });


            },
        },
        watch: {

            //Prevent logout button from being focused
            nav_val(newnav_val, oldnav_val) {
                if (newnav_val == "logout") {
                    this.nav_val = oldnav_val;
                    document.getElementById(oldnav_val + "_button").click();

                }
            },

            '$store.state.user.token': function () {
                if (this.$store.state.user.token != "") {
                    this.init();
                }
            }
        },

        mounted() {

        }
    }
</script>


<style>
    #home-container {
        max-width: 1185px;
    }
</style>